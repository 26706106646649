<template>
    <div class="w-full h-full module flex py-0">

        <div class="flex flex-col w-2/5 p-4">

            <div class="h-12 flex flex-row items-center mb-2">
                <div class="module-title self-center mr-auto w-2/12">SL</div>
                <div class="text-font-gray w-1/12">Sort By</div>
                <div class="w-8/12 h-full flex flex-col justify-center items-center">
                    <multiselect v-model="sortBy" :options="optionsFilter" label="name" />
                </div>
            </div>

            <div class="flex flex-1 min-h-0 w-full">
                <SL :sortBy="sort"/>
            </div>

        </div>
        
        <separator :direction="'vertical'" />
        <SMU :sortBy="sort"/>
    </div>
</template>
<script>
    const SL = () => import("@/components/Desktop/InterIntra/SL")
    const SMU = () => import("@/components/Desktop/InterIntra/SMU")

    export default {
        components: {
            SL, SMU
        },
        data(){
            return{
                sortBy: {name: 'Name', value: 'name', direction: 'DESC'},
                optionsFilter: this.$optionsFilterInterIntra
            }
        },
        computed:{
            sort(){
                return this.sortBy ? this.sortBy : {name: 'Name', value: 'name', direction: 'DESC'}
            }
        }
    }
</script>